var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-app",
      class: { "c-dark-theme": _vm.$store.getters.darkMode },
    },
    [
      _c(
        "CContainer",
        { staticClass: "d-flex align-items-center min-vh-100" },
        [
          _c(
            "CRow",
            { staticClass: "w-100 justify-content-center" },
            [
              _c("CCol", { attrs: { md: "6" } }, [
                _c("div", { staticClass: "w-100" }, [
                  _c(
                    "div",
                    { staticClass: "clearfix" },
                    [
                      _c("h1", { staticClass: "float-left display-3 mr-4" }, [
                        _vm._v("404"),
                      ]),
                      _c("h4", { staticClass: "pt-3" }, [
                        _vm._v(_vm._s(_vm.$t("pageNotFound.title"))),
                      ]),
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("pageNotFound.subtitle"))),
                      ]),
                      _c(
                        "CLink",
                        {
                          staticClass: "text-primary",
                          attrs: { to: _vm.$store.getters["user/homepage"] },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("pageNotFound.link")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }